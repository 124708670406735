import NewsletterSignup from 'components/core/newsletter-signup';
import styled from 'styled-components';
// import Button from 'components/formitems/button';
import quicklinks from './quicklinks';

export type NewsletterProps = {
  header?: string;
  body?: string;
  tags?: string[];
  cssClassName?: string;
};
export const Newsletter: React.FC<NewsletterProps> = ({
  header = '',
  body = '',
  tags = [],
  cssClassName = '',
}) => {
  return quicklinks?.length ? (
    <StyledNewsletter className={`pageitem newsletter row ${cssClassName}`}>
      <div className="inner">
        {header && <h3>{header}</h3>}
        {body && <p>{body}</p>}

        <NewsletterSignup mode="newsletter" tags={tags} />
      </div>
    </StyledNewsletter>
  ) : null;
};

const StyledNewsletter = styled.div`
  padding: 0 var(--side-padding);
  display: flex;
  align-items: center;
  flex-direction: column;

  .inner {
    width: 100%;
    max-width: 500px;
  }
`;

export default Newsletter;
