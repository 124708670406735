import Button from 'components/formitems/button';
import Input from 'components/formitems/input';
import React, { useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import md5 from 'md5';
import { ContextProps, GlobalContext } from 'context/global-context';

type PasswordProtectionItemProps = {
  _id: string;
  header?: string;
  body?: string;
  updatePageWithPassword: (passwordHash: string) => void;
};

const PasswordProtectionItem: React.FC<PasswordProtectionItemProps> = ({
  header,
  body,
  updatePageWithPassword,
  _id,
}) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  const { handleSubmit, errors, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const storageKey = `${_id}-password`;

  useEffect(() => {
    const storedPasswordHash = window.localStorage.getItem(storageKey);
    if (storedPasswordHash) {
      updatePageWithPassword(storedPasswordHash);
    }
  }, []); // eslint-disable-line

  const onSubmit = async (inputdata): Promise<void> => {
    const passwordHash = md5(inputdata.password);
    updatePageWithPassword(passwordHash);
    window.localStorage.setItem(storageKey, passwordHash);
  };

  return (
    <StyledPasswordProtectionItem className="pageitem password-protection">
      {header && <h2 className="display">{header}</h2>}
      {body && <p>{body}</p>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="inner">
          <Controller
            render={({ onChange, value, name }) => (
              <Input
                onChange={onChange}
                value={value}
                name={name}
                placeholder={dictionary.passwordPlaceholder}
                errorMessage={errors.password?.message || ''}
              />
            )}
            name="password"
            rules={{ required: dictionary.validationPassword }}
            control={control}
            defaultValue=""
          />
          <Button htmlType="submit">{dictionary.passwordSubmit}</Button>
        </div>
      </form>
    </StyledPasswordProtectionItem>
  );
};

const StyledPasswordProtectionItem = styled.section`
  padding: 2rem var(--side-padding);

  max-width: 500px;

  margin: 2rem auto;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      margin: 1rem 0;
    }
  }
`;

export default PasswordProtectionItem;
