import styled from 'styled-components';
import React from 'react';

export type HtmlProps = {
  body: string;
  cssClassName?: string;
};
export const Html: React.FC<HtmlProps> = ({ cssClassName, body }) =>
  body && (
    <StyledHtml
      className={`pageitem html row ${cssClassName || ''}`}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  );

const StyledHtml = styled.div`
  padding: 0 var(--side-padding);
`;

export default Html;
