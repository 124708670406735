import styled from 'styled-components';
import { mqFrom } from 'styles/styles';

type RictTextContentProps = {
  body?: string;
  cssClassName?: string;
};
const RictTextContent: React.FC<RictTextContentProps> = ({
  body,
  cssClassName = '',
}) =>
  body ? (
    <StyledRichText
      className={`pageitem rte ${
        body.length > 600 ? 'large-content' : 'teaser'
      } ${cssClassName}`}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  ) : null;

const StyledRichText = styled.div`
  padding: 1rem var(--side-padding);

  margin: 2rem auto;

  &.large-content {
    max-width: 1000px;
  }
  &.teaser {
    text-align: center;

    ${mqFrom.desktop} {
      max-width: 50%;
    }
  }

  ul {
    list-style: disc;
    margin: 1rem 0 1rem 1.4rem;

    li {
      margin: 0.5rem 0;

      p {
        margin: 0;
      }
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;

    tr:nth-child(even) {
      background-color: var(--ultra-light-background);
    }

    th {
      padding-top: 11px;
      padding-bottom: 11px;
      background-color: var(--secondary-background);
      color: var(--secondary-foreground);
    }

    td,
    th {
      border: 1px solid var(--border);
      text-align: left;
      padding: 8px;
      vertical-align: top;
    }
  }

  button {
    color: var(--button-foreground);
    background: var(--button-background);
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: var(--button-border-radius);
    white-space: nowrap;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    padding: 11px 20px;
    font-size: 0.9rem;
    font-weight: bold;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  .responsive-table {
    overflow-x: auto;
    max-width: 90vw;
  }
`;
export default RictTextContent;
