import styled from 'styled-components';

type HeaderItemProps = {
  header?: string;
  type: string;
  cssClassName?: string;
};
const HeaderItem: React.FC<HeaderItemProps> = ({
  header,
  type,
  cssClassName = '',
}) => {
  if (!header) {
    return null;
  }
  return (
    <StyledHeaderItem
      className={`pageitem header header-${type} ${cssClassName}`}
    >
      <div
        className="inner"
        dangerouslySetInnerHTML={{
          __html: `<${type} class="display">${header}</${type}>`,
        }}
      />
    </StyledHeaderItem>
  );
};
const StyledHeaderItem = styled.div`
  padding: 0 var(--side-padding);

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default HeaderItem;
