import styled from 'styled-components';
import Link from 'next/link';
import QuicklinkItem from './quicklinkItem';
import { QuickLink } from 'data/types';
import { getBoxStyles } from 'styles/styles';
import { isExternalLink } from 'utils/helpers';

export type QuicklinksProps = {
  quicklinks?: QuickLink[];
  cssClassName?: string;
};

type StyledProps = {
  items: number;
};

export const Quicklinks: React.FC<QuicklinksProps> = ({
  quicklinks,
  cssClassName = '',
}) =>
  quicklinks.length ? (
    <StyledQuickLinks
      className={`pageitem quicklinks row ${
        quicklinks.length < 4 ? 'has-few' : ''
      } ${cssClassName}`}
      items={quicklinks.filter((item) => item.image?.src).length}
    >
      {quicklinks
        .filter((item) => item.image?.src)
        .map((item) => (
          <div key={item._id} className={`quicklink `}>
            {isExternalLink(item.link || '') ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <QuicklinkItem
                  key={item._id}
                  header={item.header}
                  image={item.image}
                />
              </a>
            ) : (
              <Link prefetch={false} href={item.link}>
                <a>
                  <QuicklinkItem
                    key={item._id}
                    header={item.header}
                    image={item.image}
                  />
                </a>
              </Link>
            )}
          </div>
        ))}
    </StyledQuickLinks>
  ) : null;

const StyledQuickLinks = styled.div<StyledProps>`
  padding: var(--side-padding);
  ${(props) => getBoxStyles(props.items)}
`;

export default Quicklinks;
