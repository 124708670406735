import Head from 'next/head';
import { useRouter } from 'next/router';
import { FullPage, SimplePage, Shop } from 'data/types';
import { GetStaticProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { buildImgixUrl } from 'utils/helpers';
import Quicklinks from 'components/pageitems/quicklinks';
import Banner from 'components/pageitems/banner';
import HeaderItem from 'components/pageitems/header-item';
import RichTextContent from 'components/pageitems/richtext-content';
import Products from 'components/pageitems/products';
import Breadcrumb from 'components/core/breadcrumb';
import { getPage } from 'data/page';
import { getInitialShop } from 'data/shop';
import Layout from './master-layout';
import InstagramFeed from 'components/pageitems/instagram-feed';
import CountdownItem from 'components/pageitems/countdown';
import React, { useEffect, useState } from 'react';
import LoadingPage from './loading-page';
import PageNotFound from './404-page';
import PasswordProtectionItem from 'components/pageitems/password-protection-item';
import Buttons from 'components/pageitems/buttons';
import Html from 'components/pageitems/html';
import { PageStyles } from 'styles/styles';
import Images from 'components/pageitems/images';
import VideoEmbed from 'components/pageitems/video-embed';
import SplashProduct from 'components/pageitems/splash-product';
import Newsletter from 'components/pageitems/newsletter';
import PreviewModeButton from 'components/core/preview-mode-button';

const PAGE_ITEMS = {
  quicklinks: Quicklinks,
  banner: Banner,
  h1: HeaderItem,
  h2: HeaderItem,
  h3: HeaderItem,
  rte: RichTextContent,
  products: Products,
  instagram: InstagramFeed,
  countdown: CountdownItem,
  password: PasswordProtectionItem,
  buttons: Buttons,
  html: Html,
  images: Images,
  videoEmbed: VideoEmbed,
  'splash-product': SplashProduct,
  newsletter: Newsletter,
};

export type RegularPageProps = {
  shop: Shop;
  pages: SimplePage[];
  page: FullPage;
  preview: boolean;
};

export const RegularPage: React.FC<RegularPageProps> = ({
  page,
  shop,
  preview,
}) => {
  const router = useRouter();

  const [pageItems, setPageItems] = useState(null);

  const updateWithPass = async (passwordHash: string): Promise<void> => {
    if (passwordHash) {
      const updatedPage: FullPage = await getPage(
        router.asPath.split('?')[0],
        router.locale,
        { passwordHash }
      );
      setPageItems(updatedPage.items);
    }
  };

  useEffect(() => {
    setPageItems(null);
  }, [router.asPath]);

  if (router.isFallback) {
    return (
      <LoadingPage
        cssClassName="page fallback"
        textDictKey="loadingRegularPage"
      />
    );
  }

  if (!page) {
    return <PageNotFound />;
  }
  const { domain } = shop;
  const path = router.asPath.split('?')[0];

  const currentFullUrl = `https://${domain}${path !== '/' ? path : ''}`;
  return (
    <Layout
      cssClassName={`page ${page.settings?.cssClassName || ''}`}
      shop={shop}
      empty={page.settings?.splashPage || false}
    >
      <Head>
        <title>{page.metaTitle}</title>
        <meta name="description" content={page.metaDescription} />
        <meta name="keywords" content={page.metaKeywords} />
        <meta property="og:title" content={page.metaTitle} />
        <meta property="og:url" content={currentFullUrl} />
        {page.metaImage && (
          <meta
            property="og:image"
            content={buildImgixUrl(page.metaImage.src, {
              w: 1200,
              h: 630,
              fit: 'crop',
              crop: 'faces',
            })}
          />
        )}
        <meta name="twitter:card" content="product" />
        <meta name="twitter:title" content={page.metaTitle} />
        <meta name="twitter:description" content={page.metaDescription} />
        {page.metaImage && (
          <meta
            property="twitter:image"
            content={buildImgixUrl(page.metaImage.src, {
              w: 1200,
              h: 630,
              fit: 'crop',
              crop: 'faces',
            })}
          />
        )}
      </Head>
      {preview && <PreviewModeButton />}
      {!page.settings?.splashPage && (
        <Breadcrumb
          breadcrumb={page.breadcrumb}
          hide={page?.items?.[0]?.type === 'banner'}
        />
      )}
      <div className="main-wrapper">
        {(pageItems || page.items || []).map((item) => {
          const Page = PAGE_ITEMS[item.type];
          const additionalProps: Record<string, unknown> = {};
          if (item.type === 'password') {
            additionalProps.updatePageWithPassword = updateWithPass;
          }
          if (Page) {
            return (
              <Page
                lang="da"
                key={item._id}
                type={item.type}
                {...item.params}
                {...additionalProps}
              />
            );
          }

          // return <pre key={item._id}>{JSON.stringify(item, null, 2)}</pre>;
          return null;
        })}
      </div>

      <PageStyles settings={page.settings} />
    </Layout>
  );
};

interface Params extends ParsedUrlQuery {
  slug1?: string;
  slug2?: string;
  slug3?: string;
}
// This also gets called at build time
export const getStaticProps: GetStaticProps<RegularPageProps, Params> = async ({
  params,
  locale,
  preview,
  // previewData,
}) => {
  const path = params
    ? ['', params.slug1, params.slug2, params.slug3]
        .filter((slug) => slug !== undefined)
        .join('/')
    : '/';

  const { pages, shop } = await getInitialShop(path, locale);

  console.log('@@ page', { path, preview });

  if (!path) {
    console.log('/// getStaticProps page.js not found', {
      params,
      path,
      locale,
    });
    return { notFound: true };
  }

  const page = await getPage(path, locale);
  // Pass post data to the page via props

  return {
    props: {
      shop,
      pages,
      path,
      page: page._id !== '__404__' ? page : null,
      preview: preview || false,
    },
    revalidate: shop.devMode ? 1 : 60 * 30, // 30 minutes
  };
};
