import styled from 'styled-components';
import Countdown from '../product/countdown';

export type CountdownItemProps = {
  header?: string;
  countdownMillisecondsLeft: number;
};

export const CountdownItem: React.FC<CountdownItemProps> = ({
  countdownMillisecondsLeft,
  header,
}) => {
  if (!countdownMillisecondsLeft) {
    return null;
  }
  return (
    <StyledCountdownItem className="pageitem countdown-item">
      {header && <h2 className="display">{header}</h2>}
      <Countdown millisecondsLeft={countdownMillisecondsLeft} big />
    </StyledCountdownItem>
  );
};

const StyledCountdownItem = styled.section`
  padding: 2rem var(--side-padding);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default CountdownItem;
