import styled from 'styled-components';
import { QuickLink } from 'data/types';
import Button from 'components/formitems/button';
import { isExternalLink } from 'utils/helpers';
import Image from 'next/image';

export type ButtonsProps = {
  quicklinks?: QuickLink[];
  cssClassName?: string;
};
export const Buttons: React.FC<ButtonsProps> = ({
  quicklinks,
  cssClassName = '',
}) => {
  return quicklinks?.length ? (
    <StyledButtons className={`pageitem buttons row ${cssClassName}`}>
      {quicklinks.map((item, index) => (
        <Button
          key={item._id}
          linkProps={{
            href: item.link,
            target: isExternalLink(item.link) ? '_blank' : '_self',
          }}
          secondary={cssClassName === 'submenu-buttons'}
          display={cssClassName !== 'submenu-buttons'}
          className={`btn-${index + 1} ${item.image ? 'has-image' : ''}`}
        >
          {item.image ? (
            <Image
              className="image"
              src={item.image.src}
              width={item.image.width}
              height={item.image.height}
              layout="intrinsic"
              alt={item.image.alt || item.image.title || item.header}
            />
          ) : (
            <>{item.header}</>
          )}
        </Button>
      ))}
    </StyledButtons>
  ) : null;
};

const StyledButtons = styled.div`
  padding: 0 var(--side-padding);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;

  .btn {
    margin: 0.5rem;

    &.has-image {
      border: none;
    }
  }
`;

export default Buttons;
