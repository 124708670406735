import styled from 'styled-components';
import { QuickLink } from 'data/types';
import Image from 'next/image';
import { mqUntil } from 'styles/styles';

export type ImagesProps = {
  quicklinks?: QuickLink[];
  cssClassName?: string;
};
export const Images: React.FC<ImagesProps> = ({
  quicklinks,
  cssClassName = '',
}) =>
  quicklinks?.length ? (
    <StyledImages className={`pageitem images row ${cssClassName}`}>
      {quicklinks.map((item, index) => (
        <div className={`image image-${index + 1}`} key={item._id}>
          <Image
            className="image"
            src={item.image.src}
            width={item.image.width}
            height={item.image.height}
            layout="intrinsic"
            alt={item.image.alt || item.image.title || ''}
          />
        </div>
      ))}
    </StyledImages>
  ) : null;

const StyledImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 var(--side-padding);
  img {
    max-width: 100%;
  }
  ${mqUntil.desktop} {
    flex-direction: column;
  }
`;

export default Images;
