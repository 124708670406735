import { Image as ImageType } from 'data/types';
import styled from 'styled-components';
import Image from 'next/image';
import Button from 'components/formitems/button';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useEmblaCarousel } from 'embla-carousel/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isExternalLink } from 'utils/helpers';

export type BannerProps = {
  imagesDesktop: ImageType[];
  imagesMobile: ImageType[];
  link?: string;
  buttonText?: string;
  fullHeight?: boolean;
};

type BannerImageType = {
  image: ImageType;
  fullHeight: boolean;
};

type BannerCarouselType = {
  images: ImageType[];
  fullHeight: boolean;
};

const BannerImage: React.FC<BannerImageType> = ({ image, fullHeight }) => {
  if (fullHeight) {
    return (
      <Image
        src={image.src}
        alt={image.alt || image.title || ''}
        layout="fill"
        objectFit="cover"
      />
    );
  }

  return (
    <Image
      src={image.src}
      layout="responsive"
      width={image.width}
      height={image.height}
      alt={image.alt || image.title || ''}
    />
  );
};

const BannerCarousel: React.FC<BannerCarouselType> = ({
  images,
  fullHeight,
}) => {
  const [emblaRef, embla] = useEmblaCarousel({
    // loop: true,
    // inViewThreshold: 0,
    // containScroll: 'trimSnaps',
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
      <div className="carousel" ref={emblaRef}>
        <div className="inner">
          {(images || []).map((img) => (
            <div key={img._id} className="slide">
              <BannerImage image={img} fullHeight={fullHeight} />
            </div>
          ))}
        </div>
        <Button
          transparent
          className="left-btn"
          icon={FiChevronLeft}
          onClick={scrollPrev}
          disabled={!prevBtnEnabled}
        />
        <Button
          transparent
          icon={FiChevronRight}
          className="right-btn"
          onClick={scrollNext}
          disabled={!nextBtnEnabled}
        />
      </div>
      <div className="dots">
        {scrollSnaps.map((_, index) => (
          <div
            role="button"
            className={`dot ${index === selectedIndex ? 'selected' : ''}`}
            key={index}
            onClick={() => scrollTo(index)}
            onKeyPress={() => scrollTo(index)}
            tabIndex={0}
            aria-label="Select image"
          />
        ))}
      </div>
    </>
  );
};

const BannerImages: React.FC<BannerCarouselType> = ({
  images,
  fullHeight = false,
}) => {
  if (images.length === 1) {
    const image = images[0];

    return <BannerImage image={image} fullHeight={fullHeight} />;
  }

  return <BannerCarousel images={images} fullHeight={fullHeight} />;
};

export const Banner: React.FC<BannerProps> = ({
  link,
  imagesDesktop,
  imagesMobile,
  buttonText,
  fullHeight,
}) => {
  const router = useRouter();
  return (
    <StyledBanner
      className={`pageitem banner ${
        fullHeight ? 'full-screen-slider' : 'slider'
      } ${link ? 'has-link' : 'no-link'}`}
      onClick={() => {
        if (link) {
          if (isExternalLink(link)) {
            location.href = link;
          } else {
            router.push(link);
          }
        }
      }}
    >
      <div className="images desktop-images desktop-only">
        <BannerImages images={imagesDesktop} fullHeight={fullHeight} />
      </div>
      <div className="images mobile-images mobile-only">
        <BannerImages images={imagesMobile} fullHeight={fullHeight} />
      </div>
      {buttonText && link && (
        <div className="button-overlay">
          <Button
            className="banner-button"
            linkProps={{ href: link, prefetch: false }}
            icon={FiChevronRight}
            alignIcon="right"
            size="large"
          >
            {buttonText}
          </Button>
        </div>
      )}
    </StyledBanner>
  );
};
const StyledBanner = styled.div`
  position: relative;

  &.has-link {
    cursor: pointer;
  }

  &.full-screen-slider {
    width: 100%;
    height: calc(100vh - var(--navigation-height));

    .slide {
      width: 100%;
      height: calc(100vh - var(--navigation-height));
    }
  }

  .button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }

  .carousel {
    overflow: hidden;

    .inner {
      display: flex;

      .slide {
        position: relative;
        min-width: 100%;
      }
    }
  }

  .left-btn,
  .right-btn {
    position: absolute;
    top: calc(50% - 20px);
    transform: scale(2);
    --button-foreground: var(--secondary-background);
    --button-background: transparent;

    &.disabled {
      opacity: 0.6;
    }
  }
  .left-btn {
    left: 1.5rem;
  }

  .right-btn {
    right: 1.5rem;
  }

  .dots {
    position: absolute;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .dot {
      width: 1rem;
      height: 1rem;
      margin: 0 0.5rem;
      background: var(--secondary-background);
      border-radius: 50%;
      opacity: 0.5;
      cursor: pointer;

      &.selected {
        opacity: 1;
      }
    }
  }
`;

export default Banner;
